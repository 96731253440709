import React from 'react'
import { motion } from "framer-motion";

// altered from https://www.hover.dev/components/links

export default function IntroHeader() {
  return (
<div className="">
 
   <div className=""><div className="text flip-horizontal-bottom XLarge-text">Remixed</div> <span className="back face Large-text"> Lyrics</span></div>
   
</div>

  )
      
}
